import 'zone.js/plugins/zone-error'; // Included with Angular CLI.import { Config } from '../app/configs/index.config';
import { environment as env } from 'projects/evolutics-shared-lib/src/environments/environment';
import { environment as SDKEvt } from 'ets-fe-ng-sdk';
import { Config } from '@configs/index.config';
env.versionNo = '0.0.8';
// environment.apiBaseUrl = Config.APIDevelopmentServer;
env.apiBaseUrl = Config.APIStagingServer;
env.authenticate = true;
env.appName = `Reinsurance Portal`;
// environment.apiBaseUrl = "http://localhost:8005/core";
// environment.apiBaseUrl = " http://staging.evoluticstech.com:8005/core";
export const environment = env;

SDKEvt.reinit(environment);
