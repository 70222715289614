import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { AppService as ETSAppService } from 'ets-fe-ng-sdk';
import { TranslationService } from '@Services/translation.service';
@Injectable({
  providedIn: 'root',
})
export class AppService extends ETSAppService {
  isHome=false
  constructor(
    public titleS: Title,
    public translator: TranslationService,
  ) {
    super(titleS, translator);
  }
}
